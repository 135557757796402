<template>
    <v-card height="100">
        <v-card-text>
            <h2>Company</h2>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "account-company",
    data() {
        return {};
    },
};
</script>